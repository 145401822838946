.progress-tracker {
  display: flex;
  margin: 60px auto;
  padding: 0;
  list-style: none;
}

.progress-step {
  flex: 1 1 0%;
  margin: 0;
  padding: 0;
  min-width: 24px;
}

.progress-step:last-child {
  flex-grow: 0;
}

.progress-step:last-child .progress-marker::after {
  display: none;
}

.progress-link {
  display: block;
  position: relative;
}

.progress-marker {
  display: block;
  position: relative;
}

.progress-marker::before {
  content: attr(data-text);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
  width: 24px;
  height: 24px;
  padding-bottom: 2px;
  border-radius: 50%;
  transition: background-color, border-color;
  transition-duration: 0.3s;
}

.progress-marker::after {
  content: '';
  display: block;
  position: absolute;
  z-index: -10;
  top: 10px;
  right: -12px;
  width: 100%;
  height: 4px;
  transition: background-color 0.3s, background-position 0.3s;
}

.progress-text {
  display: block;
  padding: 8px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-title {
  margin-top: 0;
}

.progress-step .progress-marker {
  color: #fff;
}

.progress-step .progress-marker::before {
  background-color: #b6b6b6;
}

.progress-step .progress-marker::after {
  background-color: #b6b6b6;
}

.progress-step .progress-text {
  color: #333;
}

.progress-step.is-active .progress-marker::before {
  background-color: #2196F3;
}

.progress-step.is-complete .progress-marker::before {
  background-color: #1976D2;
}

.progress-step.is-complete .progress-marker::after {
  background-color: #868686;
}

.progress-step:hover .progress-marker::before {
  background-color: #56ADF5;
}

.progress-tracker--text .progress-step:last-child, .progress-tracker--center .progress-step:last-child, .progress-tracker--right .progress-step:last-child {
  flex-grow: 1;
}

.progress-tracker--center {
  text-align: center;
}

.progress-tracker--center .progress-marker::before, .progress-tracker--center .progress-text--dotted::before {
  margin-left: auto;
  margin-right: auto;
}

.progress-tracker--center .progress-marker::after {
  right: -50%;
}

.progress-tracker--right {
  text-align: right;
}

.progress-tracker--right .progress-marker::before, .progress-tracker--right .progress-text--dotted::before {
  margin-left: auto;
}

.progress-tracker--right .progress-marker::after {
  right: calc(-100% + 12px);
}

.progress-tracker--spaced .progress-marker::after {
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}

.progress-tracker--border {
  padding: 4px;
  border: 2px solid #333;
  border-radius: 32px;
}

.progress-tracker--theme-red .progress-step .progress-marker {
  color: #fff;
}

.progress-tracker--theme-red .progress-step .progress-marker::before {
  background-color: #666;
}

.progress-tracker--theme-red .progress-step .progress-marker::after {
  background-color: #666;
}

.progress-tracker--theme-red .progress-step .progress-text {
  color: #333;
}

.progress-tracker--theme-red .progress-step.is-active .progress-marker::before {
  background-color: #A62D24;
}

.progress-tracker--theme-red .progress-step.is-complete .progress-marker::before {
  background-color: #D93B30;
}

.progress-tracker--theme-red .progress-step.is-complete .progress-marker::after {
  background-color: #333;
}

.progress-tracker--theme-red .progress-step:hover .progress-marker::before {
  background-color: #DF7B74;
}

.progress-text--dotted::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  margin: 6px -2px;
  background-size: 12px 18px;
  background-image: repeating-radial-gradient(circle at center 6px, #b6b6b6, #b6b6b6 5px, rgba(182, 182, 182, 0.5) 5.5px, rgba(182, 182, 182, 0.01) 6px, transparent 100%);
}

.progress-text--dotted-1::before {
  height: 12px;
}

.progress-text--dotted-2::before {
  height: 30px;
}

.progress-text--dotted-3::before {
  height: 48px;
}

.progress-text--dotted-4::before {
  height: 66px;
}

.progress-text--dotted-5::before {
  height: 84px;
}

.progress-text--dotted-6::before {
  height: 102px;
}

.progress-text--dotted-7::before {
  height: 120px;
}

.progress-text--dotted-8::before {
  height: 138px;
}

.progress-text--dotted-9::before {
  height: 156px;
}

.progress-text--dotted-10::before {
  height: 174px;
}

.progress-text--dotted-11::before {
  height: 192px;
}

.progress-text--dotted-12::before {
  height: 210px;
}

.progress-tracker--text-top .progress-text {
  height: 100%;
}

.progress-tracker--text-top .progress-marker {
  top: -24px;
}

.progress-tracker--text-inline {
  overflow: hidden;
}

.progress-tracker--text-inline .progress-step, .progress-tracker--text-inline .progress-marker {
  display: flex;
  align-items: center;
}

.progress-tracker--text-inline .progress-marker {
  flex-grow: 1;
}

.progress-tracker--text-inline .progress-marker::after {
  top: auto;
}

.progress-tracker--text-inline .progress-text {
  position: relative;
  z-index: 30;
  max-width: 70%;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
}

.progress-tracker--text-inline .progress-marker .progress-text {
  display: inline-block;
}

.progress-tracker--text-inline .progress-title {
  margin: 0;
}

.progress-tracker--square .progress-marker::before {
  border-radius: 0;
}

.progress-tracker--square .progress-marker::after {
  top: auto;
  bottom: 0;
}

@media (max-width: 575px) {
  .progress-tracker-wrapper {
    overflow-x: auto;
    scroll-snap-type: x proximity;
  }
  .progress-tracker-wrapper .progress-step {
    min-width: 50%;
    scroll-snap-align: start;
  }
}

.progress-tracker--vertical {
  flex-direction: column;
}

.progress-tracker--vertical .progress-step {
  display: flex;
  flex: 1 1 auto;
}

.progress-tracker--vertical.progress-tracker--right .progress-step {
  flex-direction: row-reverse;
}

.progress-tracker--vertical .progress-marker::after {
  right: auto;
  top: 12px;
  left: 10px;
  width: 4px;
  height: 100%;
}

.progress-tracker--vertical .progress-text {
  padding: 0 12px 24px 12px;
}

@keyframes scale-up {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}

.anim-ripple .progress-link::before, .anim-ripple-large .progress-link::before, .anim-ripple-splash .progress-link::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 30;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  visibility: hidden;
}

.anim-ripple .progress-link:not(:active)::before, .anim-ripple-large .progress-link:not(:active)::before, .anim-ripple-splash .progress-link:not(:active)::before {
  animation: scale-up 0.3s ease-out;
}

.anim-ripple .progress-link:focus::before, .anim-ripple-large .progress-link:focus::before, .anim-ripple-splash .progress-link:focus::before {
  visibility: visible;
}

.anim-ripple.progress-tracker--center .progress-link::before, .anim-ripple.progress-tracker--center .progress-link::after, .progress-tracker--center .anim-ripple .progress-link::before, .progress-tracker--center .anim-ripple .progress-link::after, .anim-ripple-large.progress-tracker--center .progress-link::before, .anim-ripple-large.progress-tracker--center .progress-link::after, .progress-tracker--center .anim-ripple-large .progress-link::before, .progress-tracker--center .anim-ripple-large .progress-link::after, .anim-ripple-splash.progress-tracker--center .progress-link::before, .anim-ripple-splash.progress-tracker--center .progress-link::after, .progress-tracker--center .anim-ripple-splash .progress-link::before, .progress-tracker--center .anim-ripple-splash .progress-link::after, .anim-ripple-double.progress-tracker--center .progress-link::before, .anim-ripple-double.progress-tracker--center .progress-link::after, .progress-tracker--center .anim-ripple-double .progress-link::before, .progress-tracker--center .anim-ripple-double .progress-link::after {
  left: 50%;
}

.anim-ripple.progress-tracker--right .progress-link::before, .anim-ripple.progress-tracker--right .progress-link::after, .progress-tracker--right .anim-ripple .progress-link::before, .progress-tracker--right .anim-ripple .progress-link::after, .anim-ripple-large.progress-tracker--right .progress-link::before, .anim-ripple-large.progress-tracker--right .progress-link::after, .progress-tracker--right .anim-ripple-large .progress-link::before, .progress-tracker--right .anim-ripple-large .progress-link::after, .anim-ripple-splash.progress-tracker--right .progress-link::before, .anim-ripple-splash.progress-tracker--right .progress-link::after, .progress-tracker--right .anim-ripple-splash .progress-link::before, .progress-tracker--right .anim-ripple-splash .progress-link::after, .anim-ripple-double.progress-tracker--right .progress-link::before, .anim-ripple-double.progress-tracker--right .progress-link::after, .progress-tracker--right .anim-ripple-double .progress-link::before, .progress-tracker--right .anim-ripple-double .progress-link::after {
  left: calc(100% - 12px);
}

.anim-ripple-splash .progress-link::before {
  width: 48px;
  height: 48px;
  box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.35);
}

.anim-ripple-double .progress-link::before, .anim-ripple-double .progress-link::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 30;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  visibility: hidden;
  background: none;
  border: 3px solid rgba(0, 0, 0, 0.3);
}

.anim-ripple-double .progress-link:not(:active)::before, .anim-ripple-double .progress-link:not(:active)::after {
  animation: scale-up 0.3s ease-out 0s;
}

.anim-ripple-double .progress-link:not(:active)::after {
  animation-delay: 0.15s;
}

.anim-ripple-double .progress-link:focus::before, .anim-ripple-double .progress-link:focus::after {
  visibility: visible;
}

.anim--large .progress-link::before, .anim--large .progress-link::after {
  width: 48px;
  height: 48px;
}

.anim--path .progress-marker::after {
  background-image: linear-gradient(to right, #b6b6b6 50%, #868686 50%);
  background-size: 200% 100%;
  background-position: 0% 100%;
  transition: background-position 0.3s ease-out;
}

.progress-step.is-complete .anim--path .progress-marker::after {
  background-position: -100% 100%;
}

.anim--path .progress-step.is-complete .progress-marker::after {
  background-position: -100% 100%;
}
